import React from 'react';
import {connect} from '@gisatcz/ptr-state';
import Select from '../state/Select';
import LpisTest from './LpisTest';

const mapStateToProps = (state, ownProps) => {
	return {
		activeView: Select.views.getActive(state),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return () => {
		const componentId = 'ptrTest_App';
		return {};
	};
};

class App extends React.PureComponent {
	constructor(props) {
		super(props);
	}

	render() {
		const {activeView} = this.props;
		return activeView ? <LpisTest /> : null;
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
