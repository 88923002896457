import React from 'react';
import Helmet from 'react-helmet';

import {connects} from '@gisatcz/ptr-state';
import {create as createRouter} from '@gisatcz/ptr-router';
import {AppContainer} from '@gisatcz/ptr-components';
import Action from './state/Action';
import Select from './state/Select';
import config from './config';
import {init as initCore} from './core';

// base styles need to be imported before all components
import '@gisatcz/ptr-core/lib/styles/reset.css';
import '@gisatcz/ptr-core/lib/styles/base.scss';
import './styles/index.scss';

import App from './components/App';

const path = process.env.PUBLIC_URL;

function createRoutes(Store, isPreloaded) {
	return {
		'': {
			name: 'default',
		},
	};
}

function init(Store, {absPath, isPreloaded, currentUrl, navHandler}) {
	/**
	 * Creates router instance that can be used to manipulate urls.
	 *
	 * App handler updates store with current page and it's up to views to react to the change.
	 * In case of url change, redux selector possibly retrieves different data and passes them
	 * into some the component.
	 *
	 */
	const router = createRouter({
		rootUrl: absPath,
		currentUrl,
		routes: createRoutes(Store, isPreloaded),
		navHandler,
	});

	initCore({router});

	if (isPreloaded) {
		return;
	}

	Store.dispatch(Action.app.updateLocalConfiguration(config));
	Store.dispatch(Action.app.setKey('ptrTest'));
	Store.dispatch(
		Action.views.applyAndSetActive('56fb7335-367a-424b-a7a0-fb55217abd1c', {
			...Action,
		})
	).then(() => {
		// TODO temporary until views are solved properly - apply selection data from view
		const state = Store.getState();
		const view = Select.views.getActive(state);
		const selections = view?.data?.state?.selections;

		if (selections) {
			Store.dispatch(Action.selections.updateStateFromViewWithData(selections));
		}
	});
}

const ConnectedAppContainer = connects.AppContainer(AppContainer);

const AppComponent = () => {
	return (
		<>
			<Helmet defaultTitle="Ptr test" />
			<ConnectedAppContainer appKey="ptrTest">
				<App />
			</ConnectedAppContainer>
		</>
	);
};

export {AppComponent as App, init};
