import React from 'react';
import {find as _find} from 'lodash';
import {connects, connect, MountWrapper} from '@gisatcz/ptr-state';
import {
	ReactLeafletMap,
	MapSet,
	PresentationMap,
	MapControls,
} from '@gisatcz/ptr-maps';
import {
	AdjustableColumns,
	ButtonSwitch,
	ButtonSwitchOption,
} from '@gisatcz/ptr-atoms';
import {ColumnChart} from '@gisatcz/ptr-charts';
import Action from '../../state/Action';
import Select from '../../state/Select';

const periodsFilterByActive = {
	scope: true,
};
const periodsOrder = [['nameInternal', 'ascending']];

const mapStateToProps = (state, ownProps) => {
	return {
		columnChart: Select.components.getByComponentKey(
			state,
			'lpisTest_columnChart'
		),
		periods: Select.periods.getIndexed(
			state,
			periodsFilterByActive,
			null,
			periodsOrder,
			1,
			100
		),
		activePeriodKey: Select.periods.getActiveKey(state),
	};
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return () => {
		const componentId = 'ptrTest_lpisTest_App';
		return {
			onMount: () => {
				dispatch(
					Action.periods.useIndexed(
						periodsFilterByActive,
						null,
						periodsOrder,
						1,
						100,
						componentId
					)
				);
			},
			onPeriodOptionChange: option => {
				dispatch(Action.periods.setActiveKey(option.key));
			},
		};
	};
};

const ConnectedColumnChart = connects.CartesianChart(MountWrapper(ColumnChart));
const ConnectedMap = connects.Map(PresentationMap);
const ConnectedMapSet = connects.MapSet(MapSet);

class LpisTest extends React.PureComponent {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		this.props.onMount();
	}

	render() {
		const {
			onPeriodOptionChange,
			activePeriodKey,
			periods,
			columnChart,
		} = this.props;

		const activePeriod =
			periods && activePeriodKey && _find(periods, {key: activePeriodKey});

		return (
			<AdjustableColumns
				fixed
				content={[
					{
						render: props => (
							<>
								<ConnectedMapSet
									stateMapSetKey="lpisTest_mapSet"
									mapComponent={ReactLeafletMap}
									connectedMapComponent={ConnectedMap}
								>
									<MapControls levelsBased zoomOnly />
								</ConnectedMapSet>
							</>
						),
					},
					{
						width: '50%',
						minWidth: '20rem',
						render: props => (
							// TODO solve mounting
							<div className="ptrTest-side-panel">
								<div className="flex-column-box">
									<b>Period</b>
									<ButtonSwitch onClick={onPeriodOptionChange} ghost>
										{periods &&
											periods.map(period => (
												<ButtonSwitchOption
													key={period.key}
													value={period}
													active={activePeriodKey === period.key}
												>
													{period.data.nameDisplay}
												</ButtonSwitchOption>
											))}
									</ButtonSwitch>
								</div>
								{columnChart ? (
									<>
										<b>
											Top 10 areas by weigth in {activePeriod?.data.nameDisplay}
										</b>
										<ConnectedColumnChart stateComponentKey="lpisTest_columnChart" />
									</>
								) : null}
							</div>
						),
					},
				]}
			/>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LpisTest);
